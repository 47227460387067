#results table {
    width: 75%;
    margin: auto;
    border: 1px solid #73c5c7;
    text-align: center;
}

#results tr:nth-child(even) {
    background-color: rgba(115, 197, 199, .3);
}

#tbHead {
    font-weight: bolder;
    background-color: #f2a2c4;
}

#entryData {
    display: flex;
    justify-content: space-evenly;
    width: 95%;
    margin: auto;
    align-items: center;
}

#entryData span {
    color: #f2a2c4;
    text-decoration: underline;
    text-decoration-color: black;
}

@media(max-width: 500px){
    #entryData{
        flex-wrap: wrap;
    }
}
$pink: #f2a2c4;
$green: #73c5c7;

/*media breaks*/
$mobile: 600px;
$tablet: 768px;
$laptop: 992px;

.auditForm {
    display: flex;
    flex-direction: column;
    @media(max-width: $mobile){
        .auditor{
            flex-wrap: wrap;
        }
    }
    .auditor{
        width: 95%;
        margin: auto;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        h2{
            font-family: 'Poppins', sans-serif;
            align-items: center;
            font-size: 1.5rem;
            input{
                border: 1px solid $pink;
                border-radius: 5px;
            }
            select{
                border: 1px solid $pink;
                border-radius: 5px;
            }
        }
        @media(max-width: $mobile){
            h2{
                font-size: 1rem;
            }
            input{
                width: 50%;
            }
        }
        @media (min-width: $tablet){
            h2{
                font-size: 1.5rem;
            }
        }
        @media (min-width: $laptop){
            h2{
                font-size: 1.4rem;
            }
        }
    }
    .score{
        width: 80%;
        margin: auto;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        h3{
            text-align: center;
            font-family: 'Poppins', sans-serif;
            font-size: 1.5rem;
            span{
                color: $pink;
            }
        }
    
    }
    .form{
        .questions{
            display: flex;
            flex-direction: column;
            h3 {
                font-family: 'Poppins', sans-serif;
                text-align: center;
                font-size: 1.5rem;
                font-weight: bold;
                color: $pink;
                text-decoration: underline $green;
            }
            table {
                width: 50%;
                margin: auto;
                border-collapse: collapse;
                border: 1px solid $green;
                tr:nth-child(even){
                    background-color: rgba(115, 197, 199,.3);
                }
                td:nth-child(1){
                    text-align: center;
                }
                button{
                    background-color: $pink;
                    text-transform: uppercase;
                    border: none;
                    // color: #fff;
                    padding: 5%;
                    height: 2vh;
                    width: 3vw;
                    border-radius: 5px;
                }
            }
            @media(max-width: $mobile){
                table, td, tr {
                    display: block;
                    width: 80%;
                    margin: auto;
                    button{
                        margin-left: 30%;
                        width: 20vw;
                        height: 5vh;
                    }
                } 
            }
            @media(min-width: $tablet){
                table{
                    width: 90%;
                    button{
                        width: 8vw;
                        height: 4vh;
                    }
                }
            }
    
        }
        .buttons{
            display: flex;
            justify-content: space-evenly;
            margin-top: 3%;
            .moveButtons{
                width: 80%;
                margin: auto;
                display: flex;
                justify-content: space-evenly;    
            }
        }
    
    }
}
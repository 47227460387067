$pink: #f2a2c4;
$green: #73c5c7;

/*media breaks*/
$mobile: 600px;
$tablet: 768px;
$laptop: 992px;

*{
    box-sizing: border-box;
}

header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2% 5%;
    border-bottom: 2px solid $pink;
    img{
        width: 10%;
    }
    h1{
        font-family: 'Poppins', sans-serif;
        font-size: 4rem;
    }
    @media (max-width: $mobile){
        h1{
            font-size: 1.5rem;
        }
        img{
            width: 12%;
        }
    }
    @media (min-width: $tablet){
        h1{
            font-size: 1.75rem;
        }
    }
    @media (min-width: $laptop){
        h1{
            font-size: 2.5rem;
        }
    }
}

footer{
    display: flex;
    justify-content: space-evenly;
    border-top: 1px solid $green;
    margin-top: 3%;
    p{
        font-size: 1rem;
        a{
            color: #2c53a0;
            text-decoration: none;
        }
    }
}